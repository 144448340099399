import React from "react";
import './ContactCard.css';

const ContactCard = (props) => {
 return (
  <div className="contact-card">
    <div className="contact-card-body">
      <p className="contact-card-text">
        {props.children} {props.text}
      </p>
    </div>
  </div>
 );
};

export default ContactCard;