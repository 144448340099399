import React from "react";
import './ContactCard.css';

const EmailCard = (props) => {
 return (
  <div className="contact-card">
    <div className="contact-card-body">
      <p className="contact-card-text">
        {props.children} <a href="mailto:ingenieria@steel-cms.com" style={{ textDecoration: 'none' }}>{props.text}</a>
      </p>
    </div>
  </div>
 );
};

export default EmailCard;