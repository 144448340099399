import React, { useState, useRef, useEffect } from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
// import dataSlider from './dataSlider'
import styled, { css } from 'styled-components/macro';

const SliderContent = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 100px);
  color: #FFF;
  padding-left: 50px;
  
  h1 {
    font-size: clamp(1rem, 8vw, 5rem);
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: left;
    margin-bottom: 0.8rem; 
  }

  p {
    margin-bottom: 1.2rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
`;

export default function Slider({ slides }) {
  const [slideIndex, setSlideIndex] = useState(1);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setSlideIndex((slideIndex) =>
        slideIndex === length ? 1 : slideIndex + 1
      );
    };

    timeout.current = setTimeout(nextSlide, 8000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [slideIndex, length]);

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (slideIndex !== slides.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === slides.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(slides.length);
    }
  };

  return (
    <div className="container-slider" id="container-slider">
      {slides.map((obj, index) => {
        return (
          <div
            key={obj.id}
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
          >
            <img src={obj.image} />
            <div className="slider-content">
              <h1>{obj.title}</h1>
              <p>{obj.text1}</p>
              <p>{obj.text2}</p>
              <p>{obj.text3}</p>
              <p>{obj.text4}</p>
            </div>
          </div>
        );
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} />
    </div>
  );
}