import ImageOne from '../images/Project1.jpg';
import ImageTwo from '../images/Project2.jpg';
import ImageThree from '../images/Project3.jpg';
import ImageFour from '../images/Project4.jpg';
import ImageFive from '../images/Project5.jpg';
import ImageSix from '../images/Project6.jpg';
import ImageSeven from '../images/Project7.jpg';
import ImageEight from '../images/Project8.jpg';
import ImageNine from '../images/Project9.jpg';
import ImageTen from '../images/Project10.jpg';
import ImageEleven from '../images/Project11.jpg';
import ImageTwelve from '../images/Project12.jpg';

const projectData = [
  {
    title: "2022",
    subTitle: "Tanque para agua",
    text: 'Instituto Costarricense de Acueductos y Alcantarillados',
    image: ImageTwelve,
    alt: 'Slide'
  },
  {
    title: "2022",
    subTitle: "Tubería Presión Birrís",
    text: 'JASSEC',
    image: ImageEleven,
    alt: 'Slide'
  },
  {
    title: "2021",
    subTitle: "Instalación de Silo de Cemento",
    text: 'Cementos Fortaleza',
    image: ImageTen,
    alt: 'Slide'
  },
  {
    title: "2021",
    subTitle: "Instalación de Equipo Industrial",
    text: 'Bridgestone',
    image: ImageNine,
    alt: 'Slide'
  },
  {
    title: "2021",
    subTitle: "Montaje de Planta de Alimentos II Etapa",
    text: 'Dos Pinos',
    image: ImageEight,
    alt: 'Slide'
  },
  {
    title: "2021",
    subTitle: "Puente Rio Cuba Creek",
    text: 'Constructora Meco',
    image: ImageSeven,
    alt: 'Slide'
  },
  {
    title: "2021",
    subTitle: "Bridgestone Construcción Súper Estructura Bambury IV",
    text: 'SCH CONSULTORIA Y CONSTRUCCIONES TICA, S.A.',
    image: ImageOne,
    alt: 'Slide'
  },
  {
    title: "2020",
    subTitle: "Instalación de equipos de refrigeración por AMONIACO-FIFCO",
    text: 'MAYEKAWA - COSTA RICA',
    image: ImageThree,
    alt: 'Slide'
  },
  {
    title: "2019-2020",
    subTitle: "Construcción de Tanques de Acero Inoxidable para Planta de Coca Cola Libería Guanacaste",
    text: 'SEPPELEC - ESPAÑA',
    image: ImageFive,
    alt: 'Slide'
  },
  {
    title: "2018-2019",
    subTitle: "Construcción de Obras Metalmecánicas y Mecánicas, Planta de Asfalto Ochomogo - RECOPE",
    text: 'Consorcio EDICA-ELEMENTOS TECNICOS, S.A.',
    image: ImageFour,
    alt: 'Slide'
  }, 
  {
    title: "2017",
    subTitle: "Montaje mecánico de dos equipos turbogeneradores de 25 MW.",
    text: 'STE ENERGY SPA (Italia)',
    image: ImageSix,
    alt: 'Slide'
  },
  {
    title: "2016-2017",
    subTitle: "Montaje Electromecánico planta de alimento para animales.",
    text: 'Dos Pinos R.L.',
    image: ImageTwo,
    alt: 'Slide'
  }
];

export default projectData;