import { v4 as uuidv4 } from "uuid";
import ImageOne from '../images/Slider1.jpg';
import ImageTwo from '../images/Slider2.jpg';
import ImageThree from '../images/Slider3.jpg';

const dataSlider = [
  {
    id: uuidv4(),
    title: "Nuestros pilares",
    subTitle: "Pilar de clientes",
    text1: 'Ser la mejor alternativa para nuestros clientes.',
    text2: 'Ser la opción preferida de nuestra gente.',
    text3: 'Ser responsable en las prácticas sociales y ambientales.',
    text4: 'Trabajamos de forma segura.',
    path: '/path1',
    id: '1',
    image: ImageOne,
    alt: 'Slide'
  },
  {
    id: uuidv4(),
    title: "Propuesta de valor",
    subTitle: "Propuesta de valor de la Empresa",
    text1: 'Alto conocimiento y capacidad de ejecución con gran orientación a satisfacer las necesidades de nuestros cliente, a precio competitivo.',
    text2: '',
    text3: '',
    text4: '',
    path: '/path2',
    id: '2',
    image: ImageTwo,
    alt: 'Slide'
  },
  {
    id: uuidv4(),
    title: "Propósito",
    subTitle: "Propósito de la Empresa",
    text1: 'Contribuir al desarrollo de nuestro país y su gente, mediante soluciones bien ejecutadas.',
    text2: '',
    text3: '',
    text4: '',
    path: '/path3',
    id: '3',
    image: ImageThree,
    alt: 'Slide'
  }
];

export default dataSlider;