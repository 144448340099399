import React from "react";
import "./Contact.css";
import ContactCard from "./ContactCard";
import EmailCard from "./EmailCard";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";

const Contact = () => {
  return (
    <div className="contact-section" id="contact-section">
      <h1 className="header">Contáctenos</h1>
      <ContactCard title="Teléfono" text="(+506) 2430-1198">
        <BsFillTelephoneFill />
      </ContactCard>
      <ContactCard title="Teléfono" text="(+506) 2430-4296">
        <BsFillTelephoneFill />
      </ContactCard>
      <ContactCard title="Teléfono" text="(+506) 2430-4126">
        <BsFillTelephoneFill />
      </ContactCard>
      <EmailCard title="Correo Electrónico" text="ingenieria@steel-cms.com">
        <MdOutlineEmail />
      </EmailCard>
    </div>
  );
};

export default Contact;
