import React from "react";
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-bottom">
        <p>Derechos Reservados. STEEL-CMS 2022</p>
      </div>
    </div>
  );
}

export default Footer;