import React from "react";
import styled from "styled-components";
import { Button } from "../Button/Button";
import { menuData } from "../Navbar/MenuData";
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #1687A7;
  align-items: center;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)`
  color: #000D1A;
`;
const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 80px);
  text-align: center;
  margin-top: 4rem;
  // margin-bottom: 4rem;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(4, 60px);
  }
`;

const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 1.5rem;
  text-decoration: none;
  list-styles: none:
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #000D1A;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          {menuData.map((item, index) => (
          <DropdownLink to="/" key={index}>
            {item.title}
          </DropdownLink>
          ))}
        </DropdownMenu>
        {/* <BtnWrap>
          <Button primary="true" round="true" to="/contact">
            Contact Us
          </Button>
        </BtnWrap> */}
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;