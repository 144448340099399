import React from "react";
import "./Portafolio.css";

const Portafolio = () => {
  return (
    <div id="portafolio">
      <section>
        <div className="container">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <h1>¿Quiénes somos?</h1>
              <p>
                Somos una empresa sólida, responsable, muy respetuosa de su
                gente y su entorno, integrada por un grupo de trabajo que posee
                más de 20 años de experiencia en el montaje electromecánico y metalmecánico industrial, 
                así como de infraestructura.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portafolio;
