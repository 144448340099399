import React, { useState } from "react";
import styled, { css } from 'styled-components/macro'
import { Link } from "react-scroll";

import { menuData } from "./MenuData";

import Bars from '../../images/Bars.svg';
import SteelLogo from '../../images/Logo.png';
import './Navbar.css';

const NavLink = css`
  color: #FFF;
  display: flex;
  align-item: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled(Link)`
  ${NavLink}
  color: #FFF;
  display: block;
  align-item: center;
  background-image: url(${SteelLogo});
  background-size: contain;
  width: 100%;
  height: 75%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;


const NavMenuLinks = styled(Link)`
  ${NavLink}
  align-items: center;
  text-align: center;
  margin-left: 5%;
  font-size: 1.5vw;
  white-space: nowrap;
  &:hover {
    color: #D3E0EA;
    transition: 0.3s ease-in-out;
  }
  
`;

const NavMenu = styled.div`
  display: grid;
  grid-template-columns: 250px auto auto auto;
  align-items: center;
  text-align: left;
  position: absolute;
  top: 10%;
  bottom: 20%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuBars = styled.i`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    align-items: center;
    background-image: url(${Bars});
    background-size: contain;
    height: 60px;
    width: 60px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 35%);
  }
`;

const Navbar = ({ toggle }) => {

  const [navColor, setNavColor] = useState(false);
  
  const changeNavColor = () => {
    if (window.scrollY >= 100) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  }

  window.addEventListener('scroll', changeNavColor);


  return (
    <div className={navColor ? "nav nav-bg" : "nav"}>
      <MenuBars onClick={toggle} />
      <NavMenu>
      <Logo to="container-slider" spy={true} smooth={true}></Logo>
        {menuData.map((item, index) => (
          <NavMenuLinks to={item.links} spy={true} offset={-50} smooth={true} key={index}>
            {item.title}
          </NavMenuLinks>
        ))}
      </NavMenu>
    </div>
  );
};

export default Navbar;