import './App.css';
import React, { useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import Dropdown from './components/Dropdown/Dropdown';
import GlobalStyle from './globalStyles';
import Slider from './components/Slider/Slider';
import dataSlider from './data/dataSlider';
import Portafolio from './components/Portafolio/Portafolio';
import Project  from './components/Project/Project';
import Footer from './Footer/Footer';
import Contact from './components/Contact/Contact';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <GlobalStyle />
      <Navbar toggle={toggle}/>
      <Dropdown isOpen={isOpen} toggle={toggle}/>
      <Slider slides={dataSlider} />
      <Portafolio />
      <Project />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
