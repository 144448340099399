import React from "react";
import Card from "./Card";
import './Project.css';
import projectData from "../../data/projectData";

const Project = () => {
  return (
    <div className="project-section" id="project-section">
      <h1 className="header">Proyectos</h1>
      <div className="wrapper">
        <Card image={projectData[0].image} title={projectData[0].title} date={projectData[0].subTitle} text={projectData[0].text}></Card>
        <Card image={projectData[1].image} title={projectData[1].title} date={projectData[1].subTitle} text={projectData[1].text}></Card>
        <Card image={projectData[2].image} title={projectData[2].title} date={projectData[2].subTitle} text={projectData[2].text}></Card>
      </div>
      <div className="wrapper">
        <Card image={projectData[3].image} title={projectData[3].title} date={projectData[3].subTitle} text={projectData[3].text}></Card>
        <Card image={projectData[4].image} title={projectData[4].title} date={projectData[4].subTitle} text={projectData[4].text}></Card>
        <Card image={projectData[5].image} title={projectData[5].title} date={projectData[5].subTitle} text={projectData[5].text}></Card>
      </div>
      <div className="wrapper">
        <Card image={projectData[6].image} title={projectData[6].title} date={projectData[6].subTitle} text={projectData[6].text}></Card>
        <Card image={projectData[7].image} title={projectData[7].title} date={projectData[7].subTitle} text={projectData[7].text}></Card>
        <Card image={projectData[8].image} title={projectData[8].title} date={projectData[8].subTitle} text={projectData[8].text}></Card>
      </div>
      <div className="wrapper">
        <Card image={projectData[9].image} title={projectData[9].title} date={projectData[9].subTitle} text={projectData[9].text}></Card>
        <Card image={projectData[10].image} title={projectData[10].title} date={projectData[10].subTitle} text={projectData[10].text}></Card>
        <Card image={projectData[11].image} title={projectData[11].title} date={projectData[11].subTitle} text={projectData[11].text}></Card>
      </div>
    </div>
  );
};

export default Project;