import React from "react";
import './Card.css';

const Card = (props) => {
 return (
  <div className="card">
    <img src={props.image} className="card-img" />
    <div className="card-body">
      <h2 className="card-title">
        {props.title}
      </h2>
      <h3>
        {props.date}
      </h3>
      <p className="card-text">
        Cliente: {props.text}
      </p>
    </div>
  </div>
 );
};

export default Card;